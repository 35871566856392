import React from 'react';
import style from './cookie-layer.module.scss';

export class CookieLayer extends React.Component {
  state = { visible: false };

  componentDidMount() {
    if (this.props.initialVisible) {
      this.setState({ visible: true });
    }
  }

  handleSubmit = () => {
    this.props.onAccept();
    this.setState({ visible: false });
  };

  render() {
    return this.state.visible ? (
      <div className={style.cookieLayer}>
        <p>
          Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu gewährleisten. Dies schließt
          sowohl sog. "First-Party-Cookies" als auch „Third-party-requests" ein.
          <br />
          Detaillierte Informationen über den Einsatz von Cookies auf dieser Webseite finden Sie
          unter{' '}
          <a
            href="https://www.checkin-berufswelt.net/impressum-datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutzerklärung
          </a>
          <br />
          Sie können die Plattfrom auch nutzen, ohne Cookies zu aktivieren. Dies schränkt ggf. die
          Bedienbarkeit der Plattfrom ein.
        </p>
        <button onClick={this.handleSubmit}>Akzeptieren</button>
        <button
          style={{ marginRight: '10px' }}
          onClick={() => {
            this.setState({ visible: false });
          }}
        >
          Ablehnen
        </button>
      </div>
    ) : null;
  }
}
