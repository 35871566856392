import React, { Component } from 'react';

export const WindowSizeContext = React.createContext(false);

export const mobileMax = 992;
export const tabletMin = mobileMax + 1;
export const tabletMax = 1024;
export const desktopMin = tabletMax + 1;

const heightMin = 500;

export class WindowSizeProvider extends Component {
  state = {
    isMobile: false,
    lightboxMobile: false,
    viewport: undefined,
    showMap: false
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const viewport =
      window.innerWidth <= mobileMax
        ? 'mobile'
        : window.innerWidth <= tabletMax && window.innerWidth >= tabletMin
        ? 'tablet'
        : window.innerWidth >= desktopMin
        ? 'desktop'
        : 'error';
    const showMap = window.innerHeight >= heightMin;

    this.setState({
      showMap: showMap,
      isMobile: window.innerWidth < 850,
      viewport: viewport,
      lightboxMobile: window.screen ? window.screen.width < 850 : window.innerWidth < 850
    });
  };

  render() {
    const { children } = this.props;

    return (
      <WindowSizeContext.Provider
        value={{
          viewport: this.state.viewport,
          isMobile: this.state.isMobile,
          lightboxMobile: this.state.lightboxMobile,
          showMapH: this.state.showMap
        }}
      >
        {children}
      </WindowSizeContext.Provider>
    );
  }
}
