// @ts-check
import './src/polyfills';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { UserProvider } from './src/context/UserContext';
import { CookieLayer } from './src/components/cookie-layer';
import Cookies from 'universal-cookie';
import { WindowSizeProvider } from './src/context/WindowSizeContext';
import { UnsupportedBrowserWarning } from './src/components/unsupported-browser-warning';

const cookies = new Cookies();
const googleAnalyticsCookieName = 'gatsby-gdpr-google-analytics';

export const onClientEntry = () => {
  // check if the tracking cookie exists
  if (cookies.get(googleAnalyticsCookieName) === 'true') {
    // initialize google analytics with the correct ga tracking id
    //ReactGA.initialize(googleAnalyticsTrackingId);
    // initETracker();
  }
};

export const onRouteUpdate = ({ location }) => {
  // check if the tracking cookie exists
  if (cookies.get(googleAnalyticsCookieName) === 'true') {
    //ReactGA.set({ page: location.pathname, anonymizeIp: true });
    //ReactGA.pageview(location.pathname);
  }
};

// function initETracker() {
//   const script = document.createElement('script');
//   script.setAttribute('id', '_etLoader');
//   script.setAttribute('type', 'text/javascript');
//   script.setAttribute('charSet', 'UTF-8');
//   script.setAttribute('data-block-cookies', 'true');
//   script.setAttribute('data-respect-dnt', 'true');
//   script.setAttribute('data-secure-code', 'IbxCKx');
//   script.setAttribute('src', '//static.etracker.com/code/e.js');
//   document.body.appendChild(script);
// }

export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <WindowSizeProvider>
        {process.env.NODE_ENV === 'production' && (
          <CookieLayer
            onAccept={() => {
              cookies.set(googleAnalyticsCookieName, 'true');
              //ReactGA.initialize(googleAnalyticsTrackingId);
              // initETracker();
            }}
            initialVisible={cookies.get(googleAnalyticsCookieName) !== 'true'}
          />
        )}
        <UnsupportedBrowserWarning />
        {element}
      </WindowSizeProvider>
    </UserProvider>
  );
};
